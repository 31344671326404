export default defineNuxtRouteMiddleware(async () => {
  const config = useRuntimeConfig()

  const host = await useGetHost()

  const { $i18n } = useNuxtApp()

  const url = useRequestURL()

  const userdata: any = useUserData()

  const token = useCookie('token') // get token from cookies

  const authenticated = await useAuthenticated()

  if (token.value) {
    // check if value exists
    // todo verify if token is valid, before updating the state

    const headers: any = {
      'x-app-domain': host,
      'x-app-locale': $i18n.locale.value,
      'Accept': 'application/json',
      'Authorization': `Bearer ${token.value}`,
    }

    const { data, error }: any = await useFetch('/api/qdttitzxcj', {
      baseURL: config.public.apiBase,
      headers,
    })

    if (!data.value || error.value) {
      token.value = null
      authenticated.value = false
      userdata.value = {}
    }
    else {
      authenticated.value = true // update the state to authenticated
      userdata.value = data.value ?? {}
    }
  }
  else {
    // if token doesn't exist redirect to log in
    token.value = null
    authenticated.value = false
    userdata.value = {}
  }

  if (!token.value || !authenticated.value) {
    abortNavigation()
    return navigateTo({ path: $i18n.locale.value === 'vi' ? '/login' : `/${$i18n.locale.value}/login`, query: { redirect: url.href } }, { external: true })
  }
})
